@keyframes rollout {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: none;
  }
}

.roll-out {
  animation: rollout 0.6s;
}

.dropdown-select__control.dropdown-select__control--is-focused {
  font-size: 16px !important;
}
